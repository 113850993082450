export const IconHamburger = ({ className }) => {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3 6H21M3 11H21M3 16H21'
        stroke='currentColor'
        strokeWidth='1' 
        strokeLinecap='round' 
      />
    </svg>
  );
};

export const IconUnMuted = ({ stroke = 'white', ...props }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='white'
      stroke={stroke}
      className='w-5 h-5 flex items-center justify-center'
      {...props}
    >
      <g clipPath='url(#clip0_5903_1487)'>
        <path
          d='M12.2038 0.76888C11.9602 0.652681 11.6732 0.683481 11.4632 0.85288L4.6536 6.30028H1.4C0.6286 6.30028 0 6.92888 0 7.70028V13.3003C0 14.0731 0.6286 14.7003 1.4 14.7003H4.6536L11.4618 20.1477C11.5892 20.2485 11.7446 20.3003 11.9 20.3003C12.0036 20.3003 12.1072 20.2765 12.2038 20.2303C12.446 20.1141 12.6 19.8691 12.6 19.6003V1.40028C12.6 1.13148 12.446 0.88648 12.2038 0.76888Z'
          fill='white'
        />
        <path
          d='M16.1434 5.55007C15.8676 5.27847 15.4252 5.28267 15.1536 5.55567C14.882 5.83147 14.8848 6.27387 15.1592 6.54687C16.2176 7.59127 16.8 8.99547 16.8 10.5005C16.8 12.0055 16.2176 13.4097 15.1592 14.4541C14.8848 14.7243 14.882 15.1681 15.1536 15.4439C15.2908 15.5825 15.4714 15.6511 15.6506 15.6511C15.8284 15.6511 16.0062 15.5839 16.1434 15.4481C17.4706 14.1419 18.2 12.3835 18.2 10.5005C18.2 8.61747 17.4706 6.85907 16.1434 5.55007Z'
          fill='white'
        />
        <path
          d='M18.1189 3.57745C17.8431 3.30445 17.4007 3.30725 17.1277 3.58165C16.8561 3.85605 16.8589 4.29985 17.1319 4.57145C18.7237 6.14925 19.6001 8.25485 19.6001 10.5005C19.6001 12.7461 18.7237 14.8503 17.1319 16.4281C16.8589 16.7011 16.8561 17.1449 17.1277 17.4193C17.2663 17.5565 17.4455 17.6251 17.6247 17.6251C17.8025 17.6251 17.9817 17.5579 18.1189 17.4221C19.9781 15.5811 21.0001 13.1227 21.0001 10.5005C21.0001 7.87825 19.9781 5.41985 18.1189 3.57745Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_5903_1487'>
          <rect width='21' height='21' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const IconMuted = ({ stroke = 'white', ...props }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='white'
      stroke={stroke}
      className='w-5 h-5 flex items-center justify-center'
      {...props}
    >
      <g clipPath='url(#clip0_5903_1469)'>
        <path
          d='M15.7125 0.717494C15.7125 0.446111 15.5554 0.189011 15.3126 0.0747446C15.0555 -0.0538053 14.7698 -0.0109553 14.5555 0.160445L8.22803 5.20246L15.7125 12.6869V0.717494Z'
          fill='white'
        />
        <path
          d='M19.789 18.7801L1.22068 0.211823C0.942153 -0.0667015 0.489371 -0.0667015 0.210847 0.211823C-0.0676781 0.490348 -0.0676781 0.943129 0.210847 1.22165L4.70438 5.71662H4.28588C3.8431 5.71662 3.45745 5.91658 3.20035 6.23082C2.9861 6.47363 2.85755 6.80215 2.85755 7.14495V12.8583C2.85755 13.6439 3.5003 14.2866 4.28588 14.2866H7.59961L14.5556 19.8428C14.6841 19.9428 14.8413 19.9999 14.9984 19.9999C15.0984 19.9999 15.2126 19.9714 15.3126 19.9285C15.5554 19.8143 15.7125 19.5572 15.7125 19.2858V16.7248L18.7777 19.79C18.9177 19.93 19.1005 19.9999 19.2834 19.9999C19.4662 19.9999 19.649 19.93 19.789 19.7914C20.0675 19.5115 20.0675 19.0601 19.789 18.7801Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_5903_1469)'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
